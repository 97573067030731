import React, { useState, useEffect } from "react";

import "./CardsRoom.css";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import { css } from "styled-components";
import tw from "twin.macro";
import styled from "styled-components";
import firebase from "firebase";

import Follow from "../footer/Follow";
import Footer from "../footer/Footer";

const SectionHeading = tw.h2 ` pt-0   tracking-wide text-center`;
const Posts = tw.div `mt-12 flex flex-wrap -ml-0 relative`;
const Post = tw.a `flex flex-col h-full bg-white rounded no-underline p-8`;
const PostImage = styled.div `
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;
const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.p`transition duration-300 `;
const PostContainer = styled.div`
  ${tw`relative z-20 mt-0 sm:pt-3 pr-0 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2  rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw``}
    }
 
    
  `}
`;
const ContainerCust = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
  
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;


function CardsRoom() {
         
  const [Images, setImages] = useState([]);
  const temp_Images = [];
  const getImages = (section) => {

  const myRef = firebase.database().ref(`/images`);
  myRef.orderByChild('category').equalTo(`${section}`).on('value', snap => {
      if (snap.val()) {
          setImages(snap.val())
      }
  })
 }



  useEffect(() => {
    getImages('Cards')
  }, []);



   
    return(
        <div>
        <div>
             <div className="main--cont--cardsroom">
            <OpacityOverlay />
            <Container >
                <Fade bottom>
                <div className="cardsroom--main--title"><div className="textcr">CARDS ROOM</div></div>
                </Fade>
            </Container>
        </div>
        <Container className="menu--cards--sect">
                <Posts>
                {Images && Object.entries(Images).map((img, index) => {
                  return(
                    <PostContainer >
                    <Post >
                        <PostImage imageSrc={img[1].url} />
                        
                    </Post>
                    </PostContainer>
                  )
              })
                
                }
                </Posts>
            </Container>
        </div>
        <Follow />
        <Footer />
        </div>
    )
}

export default CardsRoom;