import React from "react";
import { Container } from "react-bootstrap";
import "./BgAsImage.css";
import tw from "twin.macro";
import {Fade} from "react-reveal";
import { Link } from "react-router-dom";



function BgAsImage() {
    return(
        <div>
        <div className="main--cont--bgasimage">
            
            <Container >
                <div className="cont--in--bgasimage">
                    
                    <div className="cont--in--homesectcont">
                    <Fade bottom>
                    <div className="main--sub--bgasimage"><p className="crossline--bgasimage"><span className="bgspan">THE GUEST ROOMS</span></p></div>
                    <div className="main--para--bgasimage">
                    We also have 9 AC double rooms to accommodate members  <br/> of affiliated clubs and guests of our members.
                    </div>
                    <div className="bgasimage--btn--container"><Link to="/pvtroom" className="bgasimage--btn">FIND OUT MORE</Link></div>
                    </Fade>
                    </div>
                    
                </div>
                
            </Container>
            
            
        </div>
        <Container>
        <div>
        <div className="main--sub--testim"><p className="crossline--testim"><span className="tsspan">FUTURE PLANS</span></p></div>
        <div style={{textAlign:"center", padding:"1rem 0.5rem"}} className="fut--plans">To have an anexture at outskirts of coimbatore city, with all basic amenities such as </div>
        <div style={{textAlign:"center", display:"flex", justifyContent:"center"}} className="fut--plans">
            
            <ul>
                <li>
                    CHILDRENS PARK
                </li>
                <li>
                    SWIMMING POOL 
                </li>
                <li>
                    BAR AND GUEST ROOM
                </li>
            </ul>
        </div>
        </div>
        </Container>
        </div>
        
        
    )
}

export default BgAsImage;