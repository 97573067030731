import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Entertain from "./entertain/Entertain";
import Home from "./homeComp/Home";
import FoodAndDrinks from "./foodanddrink/FoodAndDrink";
import Events from "./events/Events";
import NewNavbar from "./navbar/Navbar";
import CardsRoom from "./btnComp/CardsRoom";
import PvtRoom from "./btnComp/PvtRoom";
import Carrom from "./btnComp/Carrom";
import Gym from "./btnComp/Gym";
import Shuttle from "./btnComp/Shuttle";
import Login from "./adminComp/Login"
import Admin from "./adminComp/Admin"
import AboutUs from "./aboutComp/AboutUs"

// Private Route
import AdminRoute from "./PrivateRoutes/AdminRoutes"
// import AboutUs from "./aboutComp/AboutUs";


function App() {
  return(
    <Router>
      <NewNavbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/entertain" exact component={Entertain} />
        <Route path="/fandd" exact component={FoodAndDrinks} />
        <Route path="/events" exact component={Events} />
        <Route path="/carrom" exact component={Carrom} />
        <Route path="/gym" exact component={Gym} />
        <Route path="/shuttle" exact component={Shuttle} />
        <Route path="/cardsroom" exact component={CardsRoom} />
        <Route path="/pvtroom" exact component={PvtRoom} />
        <Route path="/about" exact component={AboutUs} />

        <Route path="/about" exact component={AboutUs} />

        <AdminRoute path="/csc/admin" exact component={Admin} />
        <Route path="/login" exact component={Login} />
      </Switch>
    </Router>
  )
}

export default App;