import {useState, useEffect, useRef} from 'react'
import './Navbar.css';
import React from 'react';
import { Button } from 'react-bootstrap';
import tw from 'twin.macro';
import styled from 'styled-components';
import CSCNAV from "../assets/images/cscnav.png"


const NavLink = tw.a`
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 text-black
  font-sans tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent  no-underline
`;


const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`sm:w-24 w-16   mt-0 z-10`}
  }
`;

export default function NewNavbar() {

    const [animClassName, setanimClassName] = useState('site-wrapper no-animation');
    const [animClassNameStatus, setanimClassNameStatus] = useState(true);

    const wrapper = useRef(document.querySelector('.site-wrapper'));
    const burger = useRef(document.querySelector('nav .burger'));



const navAnimationHandler = () => {
        setanimClassNameStatus(!animClassNameStatus)
        if(animClassNameStatus)
            setanimClassName('site-wrapper no-animation')
        else
        setanimClassName('site-wrapper mobile-open')
}



  return (
  
  <div className='nav--main'>
<div className={animClassName}>
  <div className="mobile-nav">
    <div className="links">
      <a className='nav--links' href="/entertain">ENTERTAINING</a>
      <a className='nav--links' href="/fandd">FOOD & DRINKS</a>
      <a className='nav--links' href="/events">EVENTS</a>
      <a className='nav--links' href="/about">ABOUT US</a>
    </div>
  </div>
  <div>
  <nav className='nav--flex'>
    <div className="container">
      
        <LogoLink href='/'><img src={CSCNAV} /></LogoLink>
      
      <div className="links">
        <div className="link teal">
          <a className='nav--links' href="/entertain"><span className='unav unav--anim'>ENTERTAINING</span></a>
          <div className="underline">
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
          </div>
        </div>
        <div className="link blue">
          <a className='nav--links' href="/fandd"><span className='unav unav--anim'>FOOD & DRINKS</span></a>
          <div className="underline">
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
          </div>
        </div>
        <div className="link indigo">
          <a className='nav--links' href="/events"><span className='unav unav--anim'>EVENTS</span></a>
          <div className="underline">
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
          </div>
        </div>
        <div className="link pink">
          <a className='nav--links' href="/about"><span className='unav unav--anim'>ABOUT US</span></a>
          <div className="underline">
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
            <div className="part"></div>
          </div>
        </div>
      </div>
       
      <div className="burger" onClick={() => navAnimationHandler()}>
        <div className="close" >
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="menu">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </div>
  </nav>
  </div>
  </div>
  </div>
  );
}







// import React, { useState } from "react";
// import styled from "styled-components";
// import { Container } from "react-bootstrap";
// import CSC from '../assets/images/CSC.png';
// import "./Navbar.css";
// import tw from "twin.macro";

// const Nav = styled.nav`
//   padding: 0 20px;
//   min-height: 9vh;
//   background: #26873E;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 8px;

//   @media (max-width: 768px) {
//     min-height: 6vh;
//   }
// `;

// const Logo = styled.h1`
//   font-size: 25px;
//   color: black;
// `;

// const Menu = styled.ul`
//   list-style: none;
//   display: flex;

//   li:nth-child(2) {
//     margin: 0px 20px;
//   }

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const Item = styled.li`
//   font-family: "GilroyMedium", sans-serif; 
//   cursor: pointer;
//   align-items: center;
// `;

// const Link = styled.a`
  
// `;

// const NavLink = tw.a`
//   text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 text-black
//   font-sans tracking-wide transition duration-300
//   pb-1 border-b-2 border-transparent  no-underline
// `;

// const LogoLink = styled(NavLink)`
//   ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

//   img {
//     ${tw`w-24 mt-2`}
//   }
// `;

// const NavIcon = styled.button`
//   background: none;
//   cursor: pointer;
//   border: none;
//   outline: none;

//   @media (min-width: 769px) {
//     display: none;
//   }
// `;

// const Line = styled.span`
//   padding: 0px;
//   display: flex;
//   fill: none;
//   stroke: black;
//   stroke-width: 6;
//   border: none;
//   cursor: pointer;
//   transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
//     stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

//   :nth-child(1) {
//     stroke-width: 6;
//     stroke-dasharray: ${props => (props.open ? "90 207" : "60 207")};
//     stroke-dashoffset: ${props => (props.open ? "-134" : "none")};
//   }
//   :nth-child(2) {
//     stroke-dasharray: ${props => (props.open ? "1 60" : "60 60")};
//     stroke-width: 6;
//     stroke-dashoffset: ${props => (props.open ? "-30" : "none")};
    
//   }
//   :nth-child(3) {
//     stroke-dasharray: ${props => (props.open ? "90 207" : "60 207")};
//     stroke-width: 6;
//     stroke-dashoffset: ${props => (props.open ? "-164" : "none")};
    
    
    
//   }
// `;

// const Overlay = styled.div`
//   position: absolute;
//   height: ${props => (props.open ? "91vh" : 0)};
//   width: 100vw;
//   background: #fff;
//   transition: height 0.4s ease-in-out;

//   @media (min-width: 769px) {
//     display: none;
//   }
// `;

// const OverlayMenu = styled.ul`
//   list-style: none;
//   color: #000;
//   position: absolute;
//   left: 50%;
//   top: 45%;
//   transform: translate(-50%, -50%);

//   li {
//     opacity: ${props => (props.open ? 1 : 0)};
//     font-size: 25px;
//     margin: 50px 0px;
//     transition: opacity 0.4s ease-in-out;
//   }

//   li:nth-child(2) {
//     margin: 50px 0px;
//   }
  
// `;

// function Navbar() {
//   const [toggle, toggleNav] = useState(false);
//   return (
//     <div style={{background:"#26873E", position:"sticky", top:"0px", width:"100%", boxShadow: "0px 3px 6px -6px #111", zIndex:"50", transform:"height 4s ease", opacity:"70%"}}>
//     <Container>
//       <Nav>
          
//             <LogoLink href="#"><img src={CSC}  style={{marginBottom:"5px"}}/></LogoLink>
          
//         <Menu>
//           <Item className="nav--item" style={{marginRight:"50px"}}>
//             <Link target="#" className="nav nav--link">
//               Entertaining
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"50px"}}>
//             <Link target="#" className="nav nav--link">
//               Food & Drinks
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"50px"}}>
//             <Link target="#" className="nav nav--link">
//               Events
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"50px"}}>
//             <Link target="#" className="nav nav--link">
//               Library
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"50px"}}>
//             <Link target="#" className="nav nav--link">
//               Contact
//             </Link>
//           </Item>
          
//         </Menu>
//         <NavIcon onClick={() => toggleNav(!toggle)}>
//           <Line open={toggle} />
//           <Line open={toggle} />
//           <Line open={toggle} />
//         </NavIcon>

//       </Nav>
//       </Container>
//       <Overlay open={toggle}>
//         <OverlayMenu open={toggle}>
//         <Item className="nav--item" style={{marginRight:"30px"}}>
//             <Link target="#" className="nav--menu--item">
//             Entertaining
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"40px"}}>
//             <Link target="#" className="nav--menu--item">
//             Food & Drinks
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"30px"}}>
//             <Link target="#" className="nav--menu--item">
//             Events
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"30px"}}>
//             <Link target="#" className="nav--menu--item">
//             Library
//             </Link>
//           </Item>
//           <Item className="nav--item" style={{marginRight:"30px"}}>
//             <Link target="#" className="nav--menu--item">
//             Contact
//             </Link>
//           </Item>
          
//         </OverlayMenu>
//       </Overlay>
//       </div>
//   );
// };

// export default Navbar;