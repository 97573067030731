import React from 'react';
import { Container } from "react-bootstrap";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components";
import { Fade } from "react-reveal";
import CountUp from 'react-countup';
import SomeImg from '../../assets/images/about_pic.png'
import Follow from '../../footer/Footer'
import Footer from '../../footer/Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Bearers from '../../aboutComp/Bearers';
import {Link} from "react-router-dom";
import '../../aboutComp/AboutUs.css'
import EntSection from '../../entertain/entertainComp/EntSection';
import Society from './Society';
import carrom from "../../assets/images/ent/carrom.png";
import cards from "../../assets/images/ent/cards.png";



const Post = tw.a `flex flex-col h-full bg-white rounded`;
const PostImage = styled.div `
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}  
`;
const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.p`transition duration-300 `;
const PostContainer = styled.div`
  ${tw`relative z-20 mt-0 sm:pt-3 pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw``}
    }
  `}
`;

const ContainerCust = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;
const SectionHeading = tw.h2` pt-4   tracking-wide text-center`
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)` lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl justify-center`;
const Heading = tw(SectionHeading)`xl:text-center lg:text-center sm:text-center leading-snug  mt-2 mb-10`;
const ImageColumn = tw(Column)` lg:mr-0 relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div`relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image1 = tw.img`max-w-full w-96 sm:rounded relative p-4`;


function AboutComp() {
  
  return (
    <div>
       <Container>
        <div
                  style={{
                    padding:50
                  }}
                  >
                    <Fade bottom>
                    <p
                    className='about-para-csc' style={{textAlign:"justify", textAlignLast:"center"}}
                    >
                    <b style={{color:"#26873e"}}>The	coimbatore	social	club</b>	is	established	in	year	1955	(5th	June	1955)	enthused	by	values	of	noble	ethics	
                      and	bountiful	happiness.	Having	accomplished	67	years	.	It	is	one	of	the	Coimbatore’s	premier	social	club	has	
                      member	strength	of	750	and	more.	Representing	from	various	backgrounds	with	a	unique	spirit	and	values	
                      of	the	city	.	The	club	is	located	amid	5	kms	from	Railway	station	,	Gandhipuram	,	Ukkadam	bus	stand	and	15	
                      kms	from	Airport.<br /><br />	
                      <div>
                        <Fade bottom>
                      <div style={{marginTop:70}} className="main--title--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">Founders of our club</span></p></div>
                      </Fade>
                      <div className='founders'>
                        
                        <ol>
                        <Fade bottom>
                          <li className='about--li'>
                            Mr. A.N NAYAK 
                          </li>
                          </Fade>
                          <Fade bottom>
                          <li className='about--li'>
                            Dr. J.G SHANMUGANATHAN MBBS DA (London)
                          </li>
                          </Fade>
                          <Fade bottom>
                          <li className='about--li'>
                            Mr. JAYSI PREMJI 
                          </li>
                          </Fade>
                          <Fade bottom>
                          <li className='about--li'>
                            MR. V SATHYAMOORTHY 
                          </li>
                          </Fade>
                        </ol>
                        
                        </div>
                      </div>
                      <Fade bottom>
                      <p  style={{color: "#e5cb5e", textAlign:"center"}} className='club--best'>
                      <b>
                      OUR	CLUB	IS		ONE	OF	THE	FINEST	CLUB	IN	COIMBATORE,	BEING	LOCATED	CENTRALLY	
                      </b>
                      </p>
                      </Fade>

                    </p>
                    </Fade>
          </div>
        <div>
        <Fade bottom>
          <div className="main--title--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">No. Of trustable members</span></p></div>
          </Fade>
          
          
          </div>
        <div
        className='no-of-members'
        >
        <CountUp end={750} duration={2} />

        </div>
        <Fade bottom>
        <div className="about-follow--main--title">and still counting
          </div>
          </Fade>
          <Fade bottom>
          <div style={{marginTop:100}} className="main--facility--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">FACILITIES AVAILABLE</span></p></div>
          </Fade>

                  <div
          className="fac-list-box"
                
                  >

          <ul
          >
            <Fade bottom>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Indoor badminton court with a synthetic floor
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Ample	parking	space	
                
             
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                Air	conditioned	conference	hall
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                9 Air Conditioned Rooms
                  
                </p>
                </li>
            <li className='about-facility-list-logo'
            >
                <p className='about-facility-list-txt'>
                  Table Tennis
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Full-fledged Gym
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                A/C and Non AC Cards hall

                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Reading Room
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Zero Degree AC Bar
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Lawn service
                </p>
                </li>
            <li className='about-facility-list-logo'>
                <p className='about-facility-list-txt'>
                Canteen Facility
                </p>
                </li>
                </Fade>
          </ul>
                  </div>
                  
                 
                  <div
                  style={{
                    padding:50
                  }}
                  >
                    <Fade bottom>
                    <p
                    className='about-para-csc' style={{textAlign:"justify", textAlignLast:"center"}}
                    >
                    

           At <b style={{color:"#26873e"}}>The Coimbatore Social Club</b>  we believe regular exercise has a direct bearing on fitness and health. It also becomes an important element in our lives. Our <a href="/gym" style={{color:"#e5cb5e"}}>Gym equipment</a> is really amazing, and the place is well maintained with good motivation for music. The Coimbatore Social Club offers a variety of options for its members to indulge in indoor sports activities, with the highest-quality. We have a pleasing <a href="/shuttle" style={{color:"#e5cb5e"}}>indoor badminton court</a> with a synthetic floor.

          The Coimbatore Social Club offers a high-energy, contemporary <a href="/fandd" style={{color:"#e5cb5e"}}>bar</a> serving unique cocktails with liquid crystal house music adding to the ambience.<br/><br/> Our club members believe their network has grown magnificently, The Coimbatore Social Club has given them the space to find the right people, who will help, support, and guide them. <i>“friend in need is a friend indeed”</i> – Our Club members develop a great level of bonding for one another which helps them gain trust and support to grow financially and as a good person.

<b style={{color:"#26873e"}}>The Coimbatore Social Club</b> conducts various events to encourage club members to participate and have fun. Just check this <a href='/events' style={{color:"#e5cb5e"}}>The Events</a> what our club members think about the club and their fellow members. 

                    </p>
                    </Fade>
          </div>
          {/* <div style={{paddingBottom:"2rem"}}>
                  <div className="about--btn--container"><Link to="/about" style={{textDecoration:"none"}}> <button className="about--btn" >SEE MORE</button></Link></div>
              
              </div> */}
          </Container>
          <Society />
          <Container>

          <Container style={{marginTop:100}} className="main--facility--aboutus"><p className="cross--line--aboutus"><span className="tspanabu">ENTERTAINMENT</span></p></Container>
                  
            <EntSection />
          
          
          </Container>
    </div>
  )
}

export default AboutComp;