import React from "react";
import "./Footer.css";
import styled from "styled-components";
import tw from "twin.macro";
import csc from "../assets/images/csclogo.png";
import { Container } from "react-bootstrap";

const Column = tw.div ``;
const ImageColumn = tw(Column)
` lg:mr-0 relative mt-0 lg:mt-0  z-0 justify-center`;
const ImageContainer = tw.div `relative z-0 transform xl:-translate-x-24 xl:-translate-y-16`;
const Row = tw.div `flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;

const TextColumn = tw(Column)
` lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl justify-center`;
const NavLink = tw.a `
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 text-black
  font-sans tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent  no-underline
`;


const LogoLink = styled(NavLink)
`
  ${tw` items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-56 mt-2 z-10`}
  }
`;

function Footer() {
    return(
        <div>
        <div className="footer--main--cont">
            <Container style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <Row>
              <ImageColumn>
                <ImageContainer>
                <LogoLink><img src={csc} /></LogoLink>
                </ImageContainer>
              </ImageColumn>
                
                <TextColumn>
                <div className="footer--address"> Reg no. 46 of 1955</div>
                <div className="footer--address"> 440, Thadagam Main Rd, R.S. Puram,<br/> Coimbatore, Tamil Nadu 641002</div>
                <div className="footer--mail">thecsc1965@gmail.com</div>
                </TextColumn>
                
            </Row>
            </Container>
        </div>
        <div className="thedot--cont">
            <Container className="thedot--cont--child">
              <div className="thedot--text2">CSC &copy; all rights reserved 2022.</div>
        <div className="thedot--text">powered by <span style={{cursor:"pointer"}} onClick={()=> window.open("https://www.instagram.com/thedot_tech/?hl=en", "_blank")}>theDot Tech</span></div>
        </Container>
        </div>
        </div>
        
    )
}


export default Footer;