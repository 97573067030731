import React from "react";
import { Container } from "react-bootstrap";
import "./Follow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

function Follow(){
    return(
        <div className="follow--main--cont">
            <Container>
            <div className="div--in--followbg">
            <p className="social-container">
                    <a
                    // onClick={()=> window.open("https://www.instagram.com/thedot_tech/?hl=en", "_blank")}
                    className="youtube social"
                    >
                    <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                    <a
                    // onClick={()=> window.open("https://www.instagram.com/thedot_tech/?hl=en", "_blank")}
                    className="facebook social"
                    >
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                    <a
                    // onClick={()=> window.open("https://www.instagram.com/thedot_tech/?hl=en", "_blank")}
                    className="instagram social"
                    >
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                    
                </p>
                <div className="follow--main--title"><div className="textfo">FOLLOW US</div></div>
            </div>
            </Container>
        </div>
    )
}

export default Follow;