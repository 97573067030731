import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "./EntertainMain.css"
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";


const ContainerCust = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
  
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;

function EntertainMain() {
    return(


        <div className="main--cont--entertain">
            <OpacityOverlay />
            <Container >
                <Fade bottom>
                <div className="entertain--main--title"><div className="textem">ENTERTAINMENT</div></div>
                </Fade>
            </Container>
            
        </div>
    )
}


export default EntertainMain;