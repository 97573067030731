import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container } from "react-bootstrap";
import "./Bearers.css";
import b1 from "../assets/images/bearers/b1.png";
import b2 from "../assets/images/bearers/b2.png";
import b3 from "../assets/images/bearers/b3.png";
import b4 from "../assets/images/bearers/b4.png";
import b5 from "../assets/images/bearers/b5.png";
import { Fade } from "react-reveal";



const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-12 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-0`}
  .position {
    ${tw`uppercase  tracking-widest text-xs text-black`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-black transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
    
    cards = [
      {
        imageSrc: b1,
        position: "Honorary President",
        name: "MR. SUBRAMANIAN.N",
      
      },
      {
        imageSrc: b2,
        position: "Honorary Vice President",
        name: "MR. PARAMASIVAN.D",
        
      },
      {
        imageSrc: b3,
        position: "Honorary Secretary",
        name: "MR. MURUGESAN.P",
        
      },
      {
        imageSrc: b4,
        position: "Honorary Joint Secretary",
        name: "MR. BHARATH.A",
        
      },
      {
        imageSrc: b5,
        position: "Honorary Treasurer",
        name: "MR. RAVIKUMAR.S",
        
      },

    ]
  }) => {
    return (
      <Container className="main--cont--bear">
        <Container>
          <Fade bottom>
        <div className="bear--main--title"><div className="textbr">OFFICE BEARERS</div>
        
        </div>
        </Fade>
          <Cards>
            {cards.map((card, index) => (
              <Card key={index}>
                <CardImage imageSrc={card.imageSrc} />
                <CardContent>
                <span className="name--bear">{card.name}</span>
                  <span className="position--bear">{card.position}</span>
                  
                  
                </CardContent>
              </Card>
            ))}
          </Cards>
        </Container>
      </Container>
    );
  };
  