export const login = (auth) => {
    if (auth.email === 'csc@gmail.com' && auth.passcode === 'Cbecsc') {
        if (typeof window !== "undefined") {
            localStorage.setItem('token', 'owheidfbwiehiwu6564654');

            if (localStorage.getItem('token')) return true
        }
    }
}


export const logout = () => {
    if (typeof window !== "undefined") {
        localStorage.removeItem('token')
        return true
    }

}


export const isLogin = () => {
    if (typeof window !== "undefined")
        if (localStorage.getItem('token')) return true
        else return false
}